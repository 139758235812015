import { constants } from "../../../constants";
import { useAppSelector } from "../../../effects/useAppSelector";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { PortfolioSecurity } from "../../../types/portfolio/PortfolioSecurity";
import { isRequesting } from "../../../utils/request-state.utils";
import { ValueBlocker } from "../../access/ValueBlocker";
import { ArrangerPipelineLink } from "../../amrPipeline/aggregated/arranger-pipeline/ArrangerPipelineLink";
import { Preloader } from "../../common/Preloader";

interface Props {
    security: PortfolioSecurity
}

export function PortfolioArrangersPipelineLink({ security }: Props) {
    const requestState = useAppSelector(s => s.entities.arrangersPipeline.requestState);
    const arrangerPipeline = useAppSelector(s => security.dealTicker
        ? s.entities.arrangersPipeline.items[security.dealTicker.toLowerCase()]
        : undefined
    )
    const requiredFeatures = [
        SubscriptionFeature.IssuanceMonitorArrangerPipeline,
        SubscriptionFeature.CanLookupArrangersPipelineFromPortfolio
    ];

    return (
        <Preloader small inProgress={isRequesting(requestState)}>
            <ValueBlocker features={requiredFeatures}>
                {
                    arrangerPipeline ? (
                        <ArrangerPipelineLink
                            dealsLegalNames={arrangerPipeline?.dealLegalName ? [arrangerPipeline.dealLegalName] : []}
                        >
                            View
                        </ArrangerPipelineLink>) : constants.emptyPlaceholder
                }
            </ValueBlocker>
        </Preloader>
    );
}